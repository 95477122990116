import { graphql } from "gatsby"
import React from "react"
import { Appear, CaseStudies, HomeSlider, SEO, LocaleSwitcher } from "../components"
import { HomeSlide } from "../models/HomeSlide"
import { CaseStudy } from "../models/CaseStudy"
import { Locale } from "../models/Locale"

export interface IndexPage {
  caseStudies: CaseStudy[]
  homeSlides: HomeSlide[]
  seoMetaTags: object
}

interface Props {
  data: {
    page: IndexPage
  }
}

const IndexPage = (props: Props) => {
  const { homeSlides, seoMetaTags, caseStudies } = props.data.page

  return (
    <>
      <SEO seoMetaTags={seoMetaTags} />
      <div className="container mb-4 md:hidden">
        <Appear order={1}>
          <h2 className="heading-l font-normal">We create brands the world loves.</h2>
        </Appear>
      </div>

      <Appear order={2}>
        <div className="container mb-8">
          <LocaleSwitcher locales={[Locale.English, Locale.Chinese]} path="/" />
        </div>
      </Appear>

      {homeSlides && (
        <div className="mb-16 lg:mb-20">
          <Appear order={2}>
            <HomeSlider homeSlides={homeSlides} />
          </Appear>
        </div>
      )}

      <Appear order={3}>
        <div className="container px-0 md:px-5 pb-8 lg:pb-12">
          <CaseStudies items={caseStudies} />
        </div>
      </Appear>
    </>
  )
}

export const query = graphql`
  query IndexPageQuery($locale: String!) {
    page: datoCmsIndex(locale: { eq: $locale }) {
      homeSlides {
        ...HomeSlideFragment
      }
      seoMetaTags {
        tags
      }
      caseStudies {
        id
        client
        slug
        tags
        title
        thumbnail {
          gatsbyImageData(width: 616, imgixParams: { auto: "format" })
        }
        thumbnailVideo {
          ...VideoFileFragment
        }
      }
    }
  }
`

export default IndexPage
